import React, {Component} from 'react';
import './App.css';
import './footer.css'
import FuturaLogoWhite from './logo-components/futura-logo-white';

class Footer extends Component {  

  render() {
    
    return (
      <div className="Footer">
        <div>
          <div className="">
            <FuturaLogoWhite />
          </div>
        </div> 
        <div className="social-container">
          <div className="title footer-entry">
            <p>Bolagsinformation</p>
          </div>
          <div className="footer-entry">
            <p>Vesslan 22 AB Markaryd</p>
          </div>
          <div className="footer-entry">
            <p>559289-4975</p>
          </div>
        </div>
        <div className="social-container">
          <div className="title footer-entry">
            <p>Kontaktinformation</p>
          </div>
          <div className="footer-entry">
            <a href="tel:+46760470444">+46 (0) 760 470 444</a>
          </div>
          <div className="footer-entry">
            <a href="mailto:info@futurafastigheter.se">info@futurafastigheter.se</a>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;


