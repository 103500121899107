import React, {Component} from 'react';
import './App.css';
import ScrollToTopOnMount from './scroll-to-top.js';



class About extends Component {

  showSettings (event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="About page-component">
        <ScrollToTopOnMount />
        <div className="page-title-container"><h1>OM OSS</h1></div>
        <div className="description-text-box">
        <p>Futura Fastigheter startade som en idé bland oss fyra vänner om att starta ett fastighetsbolag. Den grundar sig i ett gemensamt intresse för fastigheter, bostadsutveckling och företagande. </p>
        <p>Då vi både har våra rötter i Markaryd och fortsatt intresse i dess utveckling så var platsen ett bra alternativ för att verkställa vår idé. Efter att ha hittat vad vi anser är en perfekt tomt för den typen av bostäder som vi vill bygga, inte minst när det kommer till det centrala men naturnära läget, så bestämde vi oss för att sätta igång. Under 2021 kommer vi bygga 4 hyresrätter i form av marklägenheter på Snapphanestigen 2 i det trivsamma Södergårdsområdet, och ser såklart fram emot att välkomna våra första hyresgäster till ett boende med det lilla extra i början av 2022. </p>
        <p>Har ni frågor eller funderingar så får ni gärna höra av er till oss, kontaktinformation ser ni nedan.</p>
        <p>Betty Andertoft, Denny Ottosson, Jakob Höjdefors och Michaela Fritiofsson</p>
      </div>
    </div>
    );    
  }

}

export default About;