import rr33 from '../images/exterior/33rr.png';
import rr35 from '../images/exterior/35rr.png';
import rr36 from '../images/exterior/36rr.png';
import rr38 from '../images/exterior/38rr.png';
import rr58 from '../images/exterior/58rr.png';
import rr46 from '../images/interior/46rr.png';
import r47 from '../images/interior/47r.png';
import r48 from '../images/interior/48r.png';
import r49 from '../images/interior/49r.png';
import r50 from '../images/interior/50r.png';
import r51 from '../images/interior/51r.png';
import r53 from '../images/interior/53r.png';
import r55 from '../images/interior/55r.png';
import r57 from '../images/interior/57r.png';
import r31 from '../images/interior/31r.png';


export const exteriorPhotos = [
    {
        src: rr33,
        height: 37,
        width: 100
    },
    {
        src: rr35,
        height: 37,
        width: 100
    },
    {
        src: rr36,
        height: 39,
        width: 100
    },
    {
        src: rr38,
        height: 36,
        width: 100
    },
    {
        src: rr58,
        height: 50,
        width: 100
    },
    {
        src: rr46,
        height: 56,
        width: 100
    },
    {
        src: r47,
        height: 48,
        width: 100
    },
    {
        src: r48,
        height: 43,
        width: 100
    },
    {
        src: r49,
        height: 56,
        width: 100
    },
    {
        src: r50,
        height: 52,
        width: 100
    },
    {
        src: r51,
        height: 56,
        width: 100
    },
    {
        src: r53,
        height: 56,
        width: 100
    },
    {
        src: r55,
        height: 56,
        width: 100
    },
    {
        src: r57,
        height: 53,
        width: 100
    },
    {
        src: r31,
        height: 52,
        width: 100
    }
]