import React from 'react';
import './App.css';
import './vesslan-layout.css';
import { NavLink} from 'react-router-dom';
import FuturaLogo from './logo-components/front-logo.js';
import headerPicture from './images/header-picture.png';

  
function FrontPage() {
  return (
    <div className="FrontPage page-component">
      <img className="front-page-picture" src={headerPicture} alt="sodergardslangan_outside" />
      <div className="logo-container">
        <div className="row-container">
          <div className=""> 
            <FuturaLogo />
          </div>
        </div>
        <div className="row-container nav-row">
          <NavLink to="/projektet" className="nav-item" activeClassName="selected">
            PROJEKTET 
          </NavLink >
          <NavLink to="/intresseanmalan" className="nav-item" activeClassName="selected">
            INTRESSEANMÄLAN
          </NavLink> 
          <NavLink to="/om" className="nav-item" activeClassName="selected">
            OM FUTURA FASTIGHETER
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default FrontPage;


