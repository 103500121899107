import React, {Component} from 'react';
import './App.css';
import FuturaLogo from './logo-components/logo.js';
import headerPicture from './images/header-picture.png';
import { NavLink} from 'react-router-dom';

class Header extends Component {  

  render() {
    
    return (
      <div className="Header">
        <img className="header-picture" src={headerPicture} alt="sodergardslangan_outside" />
        <div className="logo-container">
          <NavLink to="/" className="nav-item" activeClassName="selected">
            <FuturaLogo className="header-logo" />
          </NavLink>
        </div>
      </div>
    )
  }
}

export default Header;



