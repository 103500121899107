import React, {Component} from 'react';
import './App.css';
import './form.css';
import "react-alice-carousel/lib/alice-carousel.css";
import PhotoGallery from './photo-gallery';
import { NavLink } from 'react-router-dom';
import ScrollToTopOnMount from './scroll-to-top.js';
import { exteriorPhotos } from './image-components/exterior_photos.js';
import { planskissImage } from './image-components/planskiss.js';


class RealEstateObject extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      category: {},
      photoAssets: [],
      assetUrls: [],
      photos: [{
        src: planskissImage,
        index: 0
      }]
    };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  handlechange(event){
    event.preventDefault();
    console.log("handle Change");
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handlesubmit(event){
    event.preventDefault();
    console.log("submitted");
  };

  render() {

    return (
      <div className="RestaurantMenu page-component white">
        <ScrollToTopOnMount />
        <div className="page-title-container"><h1>PROJEKT SÖDERGÅRD</h1></div>
        <div className="description-text-box">
          <p>I ett unikt läge i centrala Markaryd, och i direkt närhet till naturen, byggs nu 4 nya hyresrätter i hög standard med bästa komfort.</p>
          <p>De fyra lägenheterna är alla på 3 rum och kök, har egen parkeringsplats direkt utanför dörren och en insynsskyddad terass.</p>
          <p>Inflytt beräknas till våren 2022.</p>
        </div>
        <div className="light">
          <div className="row-container">
            <h2>BOENDET</h2>
          </div>
          <div className="row-container">
            <div className="information-container">
              <div className="information-text-container">
                <div className="information-text-entry">
                  <p className="information-text-entry-title">Storlek:</p><p className="information-text-entry-content">75 kvm</p>
                </div>
                <div className="information-text-entry">
                  <p className="information-text-entry-title">Typ:</p> <p className="information-text-entry-content">3 rum och kök</p>
                </div> 
                <div className="information-text-entry">
                  <p className="information-text-entry-title">Uppvärmning:</p><p className="information-text-entry-content">Golvvärme och värmepump</p>
                </div>
                <div className="information-text-entry">
                  <p className="information-text-entry-title">Anslutning:</p><p className="information-text-entry-content">Öppen fiber</p>
                </div>
                <div className="information-text-entry">
                  <p className="information-text-entry-title">Kök:</p><p className="information-text-entry-content">Fullutrustat Marbodal-kök med stor kyl och frys, ugn, micro och diskmaskin</p>
                </div>
                <div className="information-text-entry">
                  <p className="information-text-entry-title">Badrum:</p><p className="information-text-entry-content">Helkaklat kombinerat bad- och tvättrum i behagliga färger, utrustat med tvättmaskin och torktumlare</p>
                </div> 
              </div>
            </div>

            <div className="information-container">
              <PhotoGallery photos={planskissImage}> </PhotoGallery>
              
              
            </div>
          </div>
          <div className="row-container light">
            <div className="gallery-container">
              <PhotoGallery photos={exteriorPhotos}> </PhotoGallery>
            </div>
          </div>
        </div>
        <div className="row-container">
            <h2>LÄGET</h2>
        </div>
        <div className="row-container white">
          <div className="information-container">
            <div className="information-text-container">
              <div className="information-text-entry">
                <p className="information-text-entry-title">Adress:</p><p className="information-text-entry-content">Snapphanestigen 2, Markaryd</p>
              </div>
              <div className="information-text-entry">
                <p className="information-text-entry-title">Sjö- och grönområde:</p><p className="information-text-entry-content">100 m</p>
              </div> 
              <div className="information-text-entry">
                <p className="information-text-entry-title">Livsmedel:</p><p className="information-text-entry-content">200 m</p>
              </div>
              <div className="information-text-entry">
                <p className="information-text-entry-title">Centralstation:</p><p className="information-text-entry-content">400 m</p>
              </div>
            </div>
          </div>

        </div>
        <div>
          <NavLink to="/intresseanmalan" className="nav-item" activeClassName="selected">
            Intresseanmälan
          </NavLink >

        </div>
      </div>
    );
  }
}

export default RealEstateObject;